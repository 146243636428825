import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Segment,
  Header,
  Button,
  Icon,
  Popup,
  Loader,
  Sticky,
  Modal,
  ListContent,
  List,
  ListItem,
  ListIcon,
} from "semantic-ui-react";
import { history } from "../../../../../../../modules/history";
import toastr from "toastr";
import { nestedUpdateObject } from "../../helpers/nestedObjects";
import { generateSchema } from "../../helpers/schemaGenerator";
import { SCHEMAS } from "../../constants/schemaGenerator/schemas";
import { SOCKET_EVENTS } from "../../../../../../../constants/socketEvents";
import socketService from "../../../../../../../services/socketService";
import { filter } from "rxjs/operators";
import { fetchSchemaData } from "../../../../../../../store/actions/schemaDataActions";
// import ApplicantRefund from "../ApplicantRefund";
import {
  getApplicantByIdApi,
  updateApplicantApi,
  changePaymentStatusApi,
  changePhoneNumbers,
  continueApplicantApi,
  deleteApplicantApi,
  removeBotRunApi,
  removeEmbassyBotRunApi,
  runBotApi,
  runEmbassyBotApi,
  changeApplicantStatusToPaymentFailed,
  getGroupApplicants,
  changeApplicantType,
  getDeclarationsApi,
  changeFailsafeImmunity,
  submitIVisa,
  cancelIVisa,
  overrideApplicantApi,
  runPaymentBotApi,
  getApplicantLastError,
} from "../../../../../../../apis/applicantApi";
import StatusHistory from "../StatusHistory";
import { useSubscription } from "../../../../../../../hooks/useSubscription.hook";
import {
  getApplicantStatusString,
  getPaymentStatusString,
  PAYMENT_STATUSES,
} from "../../constants/applicantStatuses";
import confirmPopup from "../../../../../../../modules/confirmPopup";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import {
  ROLES,
  isManagerOrAdmin,
} from "../../../../../../../constants/rolesConstants";
import SmallConfirmPopup from "../../../../../../../components/SmallConfirmPopup";
import ModalButton from "../../../../../../../components/ModalButton";
import PhoneBook from "../../../../../../../components/PhoneBook";
import { API_NAMES } from "../../EVisa/constants/apiNames";
import { logServerError } from "../../../../../../../common/logs";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../constants/endpoints";
import { getDateFormat } from "../../../../../../../common/date-format";
import ModalForm from "../../../../../../../components/common/ModalForm";
import ChangeTypeForm from "./components/ChangeTypeForm";
import {
  byteArrayToUrl,
  capitalizeEachWord,
  getUpdatedApplicantProperties,
} from "../../../../../../../utils";
import ReviewCertificationSegment from "./components/ReviewCertificationSegment/ReviewCertificationSegment.component";
import apis from "../../../../../../../apis";
import moment from "moment";
import MarkAsCapturedForm from "./components/MarkAsCapturedForm";
import { EVENT_TYPE, LOG_TYPE } from "../../../../../../../constants/logs";
import ApplicantLogsTable from "./components/ApplicantLogsTable";
import ApplicantErrors from "../ApplicantErrors";
import ApplicantEmails from "../ApplicantEmails/ApplicantEmails.component";
import ValidateApplication from "../../EVisa/pages/ApplicantsEdit/components/ValidateApplication";
import { isAllowedUnicodeChars } from "../../../../../../../utils";

import "./ApplicantView.styles.scss";
import { PRODUCT_NAMES_WITH_PAYMENTS_BOTS } from "../../constants/productNames";

const SUBMIT_BUTTON_DISABLED_DS_TOOLTIP_1 =
  "Applicant has travel plans - not sure/skip/answer later is checked.";

const SUBMIT_BUTTON_DISABLED_DS_TOOLTIP_2 =
  "Applicant has choose location later selected";

const SUBMIT_BUTTON_DISABLED_GE_TOOLTIP_1 =
  "Applicant has skipped some mandatory information";

const ApplicantView = (
  {
    api,
    applicantId,
    customHeader,
    customSubmits,
    fileProps = {},
    refundProps = {},
    specifiedIcons,
  },
  ref,
) => {
  const [ivisaConfig, setIvisaConfig] = useState({});
  const [tableHeaderCollection, setTableHeaderCollection] = useState(null);
  const [fetchedApplicant, setFetchedApplicant] = useState(null);
  const [editingApplicant, setEditingApplicant] = useState(null);
  const [groupApplicants, setGroupApplicants] = useState([]);
  const [errors, setErrors] = useState(null);
  const [declarations, setDeclarations] = useState({
    applicantDeclaration: "Loading...",
    thirdPartyAuthorization: "Loading...",
    visaApplicationIssueDetected: "Loading...",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openAllSection, setOpenAllSection] = useState(true);
  const [openForceSaveModal, setOpenForceSaveModal] = useState(false);

  const user = useSelector((state) => state.currentUser);
  const { schemaData: generalSchemaData } = useSelector((state) => ({
    schemaData: state.schemaData,
  }));

  const schemaData = useMemo(
    () => generalSchemaData[api],
    [api, generalSchemaData],
  );
  const schema = useMemo(() => SCHEMAS[api], [api]);

  const dispatch = useDispatch();

  const createEventLog = useCallback(async () => {
    const differences = getUpdatedApplicantProperties(
      fetchedApplicant,
      editingApplicant,
    );
    try {
      await apis.logs.createEventLog({
        logType: LOG_TYPE.INSIDE_APPLICANT,
        eventType: EVENT_TYPE.APPLICANT_EDIT,
        userId: user.id,
        applicantId: editingApplicant.id,
        visaType: api,
        actions: differences,
      });
    } catch (e) {
      console.error(e);
    }
  }, [api, editingApplicant, fetchedApplicant, user]);

  const createTypeChangeLog = useCallback(
    async (applicationType, mainApplicationId) => {
      try {
        await apis.logs.createEventLog({
          logType: LOG_TYPE.INSIDE_APPLICANT,
          eventType: EVENT_TYPE.TYPE_CHANGE,
          userId: user.id,
          applicantId: editingApplicant.id,
          visaType: api,
          actions: [
            {
              propertyName: "applicationType",
              oldValue: null,
              newValue: applicationType,
            },
            {
              propertyName: "mainApplicationId",
              oldValue: null,
              newValue: mainApplicationId,
            },
          ],
        });
      } catch (e) {
        console.error(e);
      }
    },
    [api, editingApplicant, user],
  );

  const openDocument = useCallback(async (key, type) => {
    try {
      setIsLoading(key);
      const {
        data: {
          buffer: { data },
        },
      } = await apis.applicantDocumentHandler.getFile(key);
      const url = byteArrayToUrl(data, type);
      window.open(url, "_blank");
    } catch (e) {
      console.error(e);
      toastr.error("Failed to open file");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getSchemaData = useCallback(
    () => dispatch(fetchSchemaData(api)),
    [dispatch, api],
  );

  const getGroup = useCallback(async () => {
    try {
      const {
        data: { groupApplications },
      } = await getGroupApplicants(applicantId, api);
      setGroupApplicants(groupApplications);
    } catch (e) {
      console.error(e);
    }
  }, [api, applicantId]);

  useEffect(() => {
    getGroup();
  }, [getGroup]);

  useEffect(() => {
    if (!schemaData && api !== "inv_letter") {
      getSchemaData(api);
    }
  }, [schemaData, getSchemaData, api]);

  useEffect(() => {
    const getIvisaConfig = async () => {
      try {
        const {
          data: { isManualSubmissionEnabled, dailyQuotaLimit },
        } = await apis.configuration.ivisaConfig.getIvisaConfig();
        setIvisaConfig({ isManualSubmissionEnabled, dailyQuotaLimit });
      } catch (e) {
        console.error(e);
      }
    };
    getIvisaConfig();
  }, [setIvisaConfig]);

  const getDeclarations = useCallback(async () => {
    try {
      const { data } = await getDeclarationsApi(applicantId);
      setDeclarations(data);
    } catch (e) {
      console.error(e);
    }
  }, [applicantId]);

  useEffect(() => {
    getDeclarations();
  }, [getDeclarations]);

  useEffect(() => {
    const init = async () => {
      try {
        const { data } = await getApplicantByIdApi(
          applicantId,
          { populate: false },
          api,
        );
        if (data) {
          setEditingApplicant(data);
          setFetchedApplicant(data);
          return;
        }
      } catch (error) {
        toastr.error("This applicant doesn't exist or it got deleted");
        history.push(`/${api}`);
      }
    };
    init();
  }, [getSchemaData, applicantId, api]);

  useEffect(() => {
    const h2ElementsCollection =
      document.getElementsByClassName("segment-toggle");
    setTableHeaderCollection(h2ElementsCollection);
  }, []);

  const onStatusChange = useCallback((event) => {
    const { status } = event.data;
    setEditingApplicant((editingApplicant) => ({
      ...editingApplicant,
      status,
    }));
    toastr.success("Applicant status changed", "Status update");
  }, []);

  useSubscription(socketService.socketSubject, onStatusChange, [
    filter(
      (event) =>
        event.type === SOCKET_EVENTS[api].STATUS_CHANGED &&
        applicantId === event.data._id,
    ),
  ]);

  const onPaymentStatusChange = useCallback((event) => {
    let { paymentStatus } = event.data;

    if (paymentStatus === getPaymentStatusString(PAYMENT_STATUSES.Captured)) {
      paymentStatus = PAYMENT_STATUSES.Captured;
    }

    setEditingApplicant((editingApplicant) => ({
      ...editingApplicant,
      paymentStatus,
    }));
    toastr.info(
      `Payment status updated - '${getPaymentStatusString(paymentStatus)}'`,
      "Payment status",
    );
  }, []);

  useSubscription(socketService.socketSubject, onPaymentStatusChange, [
    filter((event) => event.type === SOCKET_EVENTS[api].NEW_APPLICANT),
  ]);

  const onGeBotUpdate = useCallback((event) => {
    const { botAccountInformation } = event.data;
    setEditingApplicant((editingApplicant) => ({
      ...editingApplicant,
      botAccountInformation,
    }));
  }, []);

  useSubscription(socketService.socketSubject, onGeBotUpdate, [
    filter(
      (event) =>
        event.type === SOCKET_EVENTS.ge.BOT_UPDATES &&
        applicantId === event.data.applicantId,
    ),
  ]);

  const submitHandler = useCallback(
    async (disableValidation) => {
      // Validate all string fields before submitting for ds11
      if (api === API_NAMES.DS11) {
        
        const stringFields = Object.entries(editingApplicant).reduce((acc, [key, value]) => {
          if (typeof value === 'string') {
            acc.push([key, value]);
          } else if (typeof value === 'object' && value !== null) {
            Object.entries(value).forEach(([subKey, subValue]) => {
              if (typeof subValue === 'string') {
                acc.push([`${key}.${subKey}`, subValue]);
              }
            });
          }
          return acc;
        }, []);
        
        const invalidFields = stringFields.filter(([_, value]) => !isAllowedUnicodeChars(value));
         
        if (invalidFields.length > 0) {
          const fieldNames = invalidFields.map(([field]) => field).join(", ");
          toastr.error(`Invalid characters found in fields: ${fieldNames}`);
          return;
        }
      }

      try {
        const { data } = await updateApplicantApi(
          editingApplicant._id,
          editingApplicant,
          api,
          disableValidation,
        );
        createEventLog();
        setEditingApplicant(data);
        setErrors(null);
        setOpenForceSaveModal(false);
        toastr.success("Applicant successfully changed!", "OK");
      } catch (error) {
        if (error.response && [422, 409].includes(error.response.status)) {
          const { errors } = error.response.data.error;
          if (api === API_NAMES.DS160 && errors) {
            const errorsArray = Object.keys(errors);
            if (
              errorsArray.length === 1 &&
              errorsArray[0] === "arrivalDate" &&
              editingApplicant.embassyPasswordHistory[
                editingApplicant.embassyPasswordHistory.length - 1
              ] !== editingApplicant.embassyPassword
            )
              setOpenForceSaveModal(errors[errorsArray[0]].value);
          }
          if (api === API_NAMES.SG && errors) {
            const errorsArray = Object.keys(errors);
            if (errorsArray.every((el) => el.endsWith("dateOfArrival")))
              setOpenForceSaveModal(errors[errorsArray[0]].value);
          }
          if (api === API_NAMES.SCHENGEN && errors) {
            const errorsArray = Object.keys(errors);
            if (
              errorsArray.length &&
              moment(editingApplicant.createdAt).isBefore(
                moment([2024, 1, 8, 15]),
              )
            )
              setOpenForceSaveModal(editingApplicant.createdAt);
          }

          if ([API_NAMES.DS11, API_NAMES.DS82].includes(api) && errors) {
            const errorsArray = Object.keys(errors);
            setErrors(errors);
            setOpenForceSaveModal(true);
            return;
          }

          setErrors(errors);
          Object.values(errors).forEach((error) => {
            toastr.error(error.message, "Error");
          });
        } else {
          toastr.error(
            "Applicant cannot be submitted! Validation error.",
            "Error",
          );
        }
      }
    },
    [createEventLog, editingApplicant, api],
  );

  const handleFailsafeImmunity = useCallback(async () => {
    try {
      await changeFailsafeImmunity(
        editingApplicant._id,
        !editingApplicant?.deletionInformation?.failsafeImmunity,
        api,
      );
      setEditingApplicant({
        ...editingApplicant,
        deletionInformation: {
          ...editingApplicant?.deletionInformation,
          failsafeImmunity:
            !editingApplicant?.deletionInformation?.failsafeImmunity,
        },
      });
      setErrors(null);
      toastr.success(
        `Applicant failsafe immunity set to ${!editingApplicant
          ?.deletionInformation?.failsafeImmunity}!`,
        "OK",
      );
    } catch (error) {
      toastr.error("Something went wrong! Please try again.", "Error");
    }
  }, [api, editingApplicant]);

  const changeState = useCallback(
    (propName, propValue, propPath, afterChange = () => {}) => {
      if (propPath) {
        const propNames = propPath.split(".");
        setEditingApplicant((editingApplicant) => ({
          ...editingApplicant,
          ...nestedUpdateObject(
            propNames,
            propName,
            propValue,
            editingApplicant,
          ),
        }));
        return;
      } else {
        setEditingApplicant((editingApplicant) => ({
          ...editingApplicant,
          [propName]: propValue,
        }));
      }
      afterChange();
    },
    [],
  );

  const renderIcons = useCallback(
    (property, getContent, onClick, color, iconName, reverse = false) => {
      if (!editingApplicant || !editingApplicant[property]) {
        return;
      }
      const items = editingApplicant[property];

      if (Array.isArray(items)) {
        if (reverse) {
          items.reverse();
        }
        return items.map((value, index) =>
          !getContent ? (
            <Button
              onClick={() => onClick(value)}
              color={color}
              size="medium"
              icon
              key={index}
            >
              {index + 1}.<Icon name={iconName} />
            </Button>
          ) : (
            <Popup
              key={index}
              content={getContent(value)}
              trigger={
                value.key === isLoading || value === isLoading ? (
                  <Loader inline active />
                ) : (
                  <Button
                    onClick={() => onClick(value)}
                    color={color}
                    size="medium"
                    icon
                  >
                    {index + 1}.<Icon name={iconName} />
                  </Button>
                )
              }
            />
          ),
        );
      }
      return (
        <Popup
          content={getContent(items)}
          trigger={
            items.key === isLoading || items === isLoading ? (
              <Loader inline active />
            ) : (
              <Button
                onClick={() => onClick(items)}
                color={color}
                size="medium"
                icon
              >
                <Icon name={iconName} />
              </Button>
            )
          }
        />
      );
    },
    [editingApplicant, isLoading],
  );

  const renderHeaderLinks = useCallback(() => {
    if (!tableHeaderCollection) {
      return null;
    }

    const arrayOfLinks = [];

    for (let i = 0; i < tableHeaderCollection.length; i++) {
      const button = (
        <button
          key={i}
          type="button"
          className="table-link-button"
          onClick={() => {
            if (!tableHeaderCollection[i].className.includes("open"))
              tableHeaderCollection[i].click();
            tableHeaderCollection[i].scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }}
        >
          {tableHeaderCollection[i].innerText}
        </button>
      );
      arrayOfLinks.push(button);
    }

    return arrayOfLinks;
  }, [tableHeaderCollection]);

  const changePaymentStatusHandler = useCallback(
    async (id, paymentStatus, api, orderId) => {
      try {
        await changePaymentStatusApi(id, paymentStatus, api, orderId);
        setEditingApplicant((applicant) => {
          return { ...applicant, paymentStatus, orderId };
        });
      } catch (error) {
        console.error(error);
        logServerError(error);
      }
    },
    [],
  );

  const deleteApplicantsHandler = useCallback(
    (applicantId) => async () => {
      try {
        await deleteApplicantApi(applicantId, api);
        history.push("/");
        toastr.success("Applicant successfully deleted!", "OK");
      } catch (error) {
        toastr.error("Applicant cannot be deleted!", "Error");
      }
    },
    [api],
  );

  const changePhoneNumberHandler = useCallback(
    (applicantId) => async (phoneNumbers) => {
      try {
        const {
          data: { data: newPhoneNumbers },
        } = await changePhoneNumbers(applicantId, phoneNumbers, api);
        setEditingApplicant((applicant) => {
          return { ...applicant, phoneNumbers: newPhoneNumbers };
        });
      } catch (error) {
        console.error(error);
      }
    },
    [api],
  );

  const submitToIVisa = useCallback(async (applicantId) => {
    try {
      await submitIVisa(applicantId);
    } catch (err) {
      logServerError(err);
    }
  }, []);

  const cancelFromIVisa = useCallback(async (applicantId) => {
    try {
      await cancelIVisa(applicantId);
      setEditingApplicant((applicant) => {
        return { ...applicant, submittedToIvisa: false };
      });
    } catch (err) {
      logServerError(err);
    }
  }, []);

  const botRunHandler = useCallback(
    async (applicantId) => {
      try {
        await runBotApi(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const botRunPaymentHandler = useCallback(
    async (applicantId) => {
      try {
        await runPaymentBotApi(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const botContinueHandler = useCallback(
    async (applicantId) => {
      try {
        await continueApplicantApi(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const botOverrideHandler = useCallback(
    async (applicantId) => {
      try {
        await overrideApplicantApi(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const botRunEmbassyHandler = useCallback(
    async (applicantId) => {
      try {
        await runEmbassyBotApi(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const getApplicantNewestError = useCallback(async () => {
    try {
      setIsLoading(true);
      const errorsResponse = await getApplicantLastError(applicantId, api);
      errorsResponse?.data?.errorImage
        ? openDocument(errorsResponse.data.errorImage, "image/jpg")
        : toastr.error("This error doesn't contain screenshot");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [api, applicantId, openDocument]);

  const botRunRemoveHandler = useCallback(
    async (applicantId) => {
      try {
        await removeBotRunApi(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const botRunRemoveEmbassyHandler = useCallback(
    async (applicantId) => {
      try {
        await removeEmbassyBotRunApi(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const changeStatusToPaymentFailedHandler = useCallback(
    async (applicantId) => {
      try {
        await changeApplicantStatusToPaymentFailed(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const changeApplicantTypeHandler = useCallback(
    async (id, { applicationType, mainApplicationId }) => {
      try {
        const { data } = await changeApplicantType(
          id,
          applicationType,
          mainApplicationId,
          api,
        );
        createTypeChangeLog(applicationType, mainApplicationId);
        setEditingApplicant(data);
        getGroup();
        toastr.success("Applicant type successfully changed!");
      } catch (error) {
        error?.response?.data?.message &&
          toastr.error(error.response.data.message);
        console.error(error);
      }
    },
    [api, createTypeChangeLog, getGroup],
  );

  const additionalActions = useCallback(
    (applicant) => {
      const { _id, status, applicantNumber } = applicant;

      let buttons = null;

      const statusString = getApplicantStatusString(status);

      const changeStatusToPaymentFailed = [
        API_NAMES.ESTA,
        API_NAMES.VN,
      ].includes(api) &&
        !["Approved", "Rejected"].includes(status) && (
          <Popup
            content={`Change status to "Payment failed"`}
            trigger={
              <span>
                <SmallConfirmPopup
                  header={`Are you sure you want to change status to "Payment failed"?`}
                  callback={() => {}}
                  content={
                    <div>
                      <Button
                        onClick={() => changeStatusToPaymentFailedHandler(_id)}
                        negative
                        floated="right"
                        content="Change"
                      />
                    </div>
                  }
                >
                  <Button color="orange" size="mini" icon>
                    <Icon name="warning" />
                  </Button>
                </SmallConfirmPopup>
              </span>
            }
          />
        );

      const continueApplicantButton = ((api === API_NAMES.DS160 &&
        ![
          "Submitted",
          "Embassy bot in queue",
          "Embassy bot returned error",
          "Embassy bot in progress",
        ].includes(statusString) &&
        applicantNumber) ||
        (((statusString === "Payment failed - will retry payment" &&
          applicantNumber) ||
          statusString === "Bot returned error" ||
          statusString === "Pending submission") &&
          API_NAMES.ESTA === api) ||
        (statusString === "Payment failed - will retry payment" &&
          API_NAMES.VN === api)) && (
        <Popup
          content={
            api === API_NAMES.IND && !editingApplicant?.files?.passportPdf
              ? "This applicant needs passport PDF!"
              : api === API_NAMES.ESTA && editingApplicant?.submittedToIvisa
              ? "This applicant is already submitted to iVisa"
              : "Continue submitting the application"
          }
          trigger={
            <span>
              <SmallConfirmPopup
                header={
                  "Are you sure you want to continue processing the applicant?"
                }
                callback={() => {}}
                content={
                  <div>
                    <Button
                      onClick={() => botContinueHandler(_id)}
                      positive
                      floated="right"
                      content="Start"
                    />
                  </div>
                }
              >
                <Button
                  color="yellow"
                  size="mini"
                  disabled={
                    editingApplicant?.submittedToIvisa && api === API_NAMES.ESTA
                      ? true
                      : false
                  }
                  icon
                >
                  <Icon name="angle double right" />
                </Button>
              </SmallConfirmPopup>
            </span>
          }
        />
      );

      const embassyBotRun = api === API_NAMES.DS160 &&
        (statusString === "Submitted" ||
          statusString === "Embassy bot returned error") &&
        ["A", "B"].includes(applicant.visaType) && (
          <SmallConfirmPopup
            header={
              "Are you sure you want to create Embassy account for this applicant?"
            }
            callback={() => {}}
            content={
              <div>
                <Button
                  onClick={() => botRunEmbassyHandler(_id)}
                  positive
                  floated="right"
                  content="Start"
                />
              </div>
            }
          >
            <Button color="blue" size="mini" icon>
              <Icon name="play circle" />
            </Button>
          </SmallConfirmPopup>
        );

      const removeBotRunHandler = (
        <Popup
          content={"Remove bot from the queue"}
          trigger={
            <span>
              <SmallConfirmPopup
                header={
                  "Are you sure you want to remove this application from queue?"
                }
                callback={() => {}}
                content={
                  <div>
                    <Button
                      onClick={() => botRunRemoveHandler(_id)}
                      positive
                      floated="right"
                      content="Remove"
                    />
                  </div>
                }
              >
                <Button color="purple" size="mini" icon>
                  <Icon name="clipboard" />
                </Button>
              </SmallConfirmPopup>
            </span>
          }
        />
      );
      const overrideApplicantButton = api === API_NAMES.NZ &&
        ["Applicant has valid NZeTA, proceed with override if needed"].includes(
          statusString,
        ) && (
          <Popup
            content={"Override the application"}
            trigger={
              <span>
                <SmallConfirmPopup
                  header={"Are you sure you want to override the applicant?"}
                  callback={() => {}}
                  content={
                    <div>
                      <Button
                        onClick={() => botOverrideHandler(_id)}
                        positive
                        floated="right"
                        content="Start"
                      />
                    </div>
                  }
                >
                  <Button color="yellow" size="mini" icon>
                    <Icon name="angle double right" />
                  </Button>
                </SmallConfirmPopup>
              </span>
            }
          />
        );

      const runPaymentBot = Object.keys(
        PRODUCT_NAMES_WITH_PAYMENTS_BOTS,
      ).includes(api) &&
        ["Submitted", "Processed manually", "Payment failed"].includes(
          statusString,
        ) && (
          <Popup
            content={"Start the payment bot"}
            trigger={
              <span>
                <SmallConfirmPopup
                  header={"Are you sure you want to run the payment bot?"}
                  callback={() => {}}
                  content={
                    <div>
                      <Button
                        onClick={() => botRunPaymentHandler(_id)}
                        positive
                        floated="right"
                        content="Start"
                      />
                    </div>
                  }
                >
                  <Button color="blue" size="mini" icon>
                    <Icon name="credit card outlined" />
                  </Button>
                </SmallConfirmPopup>
              </span>
            }
          />
        );

      switch (statusString) {
        case "Pending submission":
          buttons = (
            <React.Fragment>
              {buttons}
              {
                <Popup
                  content={
                    api === API_NAMES.IND &&
                    !editingApplicant?.files?.passportPdf
                      ? "This applicant needs passport PDF!"
                      : api === API_NAMES.ESTA &&
                        editingApplicant?.submittedToIvisa
                      ? "This applicant is already submitted to iVisa"
                      : "Start a new application"
                  }
                  trigger={
                    <span>
                      <SmallConfirmPopup
                        header={"Are you sure you want to run the bot?"}
                        callback={() => {}}
                        content={
                          <div>
                            <Button
                              onClick={() => botRunHandler(_id)}
                              positive
                              floated="right"
                              content="Start"
                            />
                          </div>
                        }
                      >
                        <Button
                          color="green"
                          size="mini"
                          icon
                          disabled={
                            (api === API_NAMES.IND &&
                              !editingApplicant?.files?.passportPdf) ||
                            (api === API_NAMES.ESTA &&
                            editingApplicant?.submittedToIvisa
                              ? true
                              : false)
                          }
                        >
                          <Icon name="play" />
                        </Button>
                      </SmallConfirmPopup>
                    </span>
                  }
                />
              }
              {changeStatusToPaymentFailed}
              {continueApplicantButton}
              {removeBotRunHandler}
            </React.Fragment>
          );
          break;
        case "Bot in queue":
          buttons = (
            <React.Fragment>
              {buttons}
              {removeBotRunHandler}
            </React.Fragment>
          );
          break;
        case "Embassy bot in queue":
          buttons = (
            <React.Fragment>
              {buttons}
              <SmallConfirmPopup
                header={
                  "Are you sure you want to remove this application from queue?"
                }
                callback={() => {}}
                content={
                  <div>
                    <Button
                      onClick={() => botRunRemoveEmbassyHandler(_id)}
                      positive
                      floated="right"
                      content="Remove"
                    />
                  </div>
                }
              >
                <Button color="purple" size="mini" icon>
                  <Icon name="clipboard" />
                </Button>
              </SmallConfirmPopup>
            </React.Fragment>
          );
          break;
        case "Bot in progress":
          buttons = (
            <React.Fragment>
              {buttons}
              <Button color="green" size="mini" icon loading disabled>
                <Icon name="play" />
              </Button>
            </React.Fragment>
          );
          break;
        case "Embassy bot in progress":
          buttons = (
            <React.Fragment>
              {buttons}
              <Button color="blue" size="mini" icon loading disabled>
                <Icon name="play" />
              </Button>
            </React.Fragment>
          );
          break;
        case "Bot returned error":
          buttons = (
            <React.Fragment>
              {buttons}
              <Popup
                content={"Preview newest error"}
                trigger={
                  <span>
                    <Button
                      onClick={getApplicantNewestError}
                      color="red"
                      size="mini"
                      icon
                    >
                      <Icon name="exclamation triangle" />
                    </Button>
                  </span>
                }
              />
              {continueApplicantButton}
              {removeBotRunHandler}
            </React.Fragment>
          );
          break;
        default:
          buttons = (
            <React.Fragment>
              {buttons}
              {continueApplicantButton}
              {overrideApplicantButton}
              {embassyBotRun}
              {changeStatusToPaymentFailed}
              {runPaymentBot}
            </React.Fragment>
          );
          break;
      }
      return buttons;
    },
    [
      api,
      editingApplicant,
      changeStatusToPaymentFailedHandler,
      botContinueHandler,
      botRunEmbassyHandler,
      botRunRemoveHandler,
      botOverrideHandler,
      botRunPaymentHandler,
      getApplicantNewestError,
      botRunHandler,
      botRunRemoveEmbassyHandler,
    ],
  );

  const actions = useMemo(() => {
    if (!editingApplicant) {
      return;
    }

    const { _id, paymentStatus, phoneNumbers } = editingApplicant;

    let buttons = null;

    if (paymentStatus === PAYMENT_STATUSES.Unpaid) {
      buttons = (
        <Popup
          content={"Mark application as authorized/captured"}
          trigger={
            <span>
              <ModalForm
                headerText="Mark application as authorized/captured"
                loaderText="Preparing data..."
                onSubmit={(result) =>
                  changePaymentStatusHandler(
                    _id,
                    PAYMENT_STATUSES.Captured,
                    api,
                    result?.orderId,
                  )
                }
                className="change-type-modal"
                closeOnSubmit
                trigger={
                  <Button color="green" size="mini" icon>
                    <Icon name="dollar" />
                  </Button>
                }
              >
                <MarkAsCapturedForm applicant={editingApplicant} />
              </ModalForm>
            </span>
          }
        />
      );
    }

    if (user && user.roles.includes(ROLES.ADMIN)) {
      buttons = (
        <React.Fragment>
          {buttons}
          <Popup
            content={"Delete application"}
            trigger={
              <span>
                <SmallConfirmPopup
                  header="Are you sure you want to delete the applicant?"
                  content={<Button positive floated="right" content="Delete" />}
                  callback={deleteApplicantsHandler(_id)}
                >
                  <Button color="red" size="mini" icon>
                    <Icon name="trash" />
                  </Button>
                </SmallConfirmPopup>
              </span>
            }
          />
        </React.Fragment>
      );
    }

    const { canCall, canSendMessage } = user.permissions;
    if (canCall || canSendMessage) {
      buttons = (
        <React.Fragment>
          {buttons}
          <Popup
            content={"Call the customer"}
            trigger={
              <span>
                <ModalButton
                  buttonProps={{ color: "green", size: "mini" }}
                  buttonClassName={"icon"}
                  buttonIcon="call"
                  modalComponent={
                    <PhoneBook
                      numbers={phoneNumbers}
                      applicant={editingApplicant}
                      onChange={changePhoneNumberHandler(_id)}
                    />
                  }
                ></ModalButton>
              </span>
            }
          />
        </React.Fragment>
      );
    }

    // buttons = (
    //   <React.Fragment>
    //     {buttons}
    //     <ApplicantRefund applicant={editingApplicant} api={api}>
    //       <Button color="red" size="mini" icon>
    //         <Icon name="dollar" />
    //       </Button>
    //     </ApplicantRefund>
    //   </React.Fragment>
    // );

    buttons = (
      <React.Fragment>
        {buttons}
        <Popup
          content="Immunity set -  if true we will not delete the application"
          trigger={
            <Button
              color="orange"
              size="mini"
              icon
              onClick={() => handleFailsafeImmunity()}
            >
              <Icon name="user cancel" />
            </Button>
          }
        />
      </React.Fragment>
    );

    buttons = (
      <React.Fragment>
        {buttons}
        {additionalActions(editingApplicant)}
      </React.Fragment>
    );

    return buttons;
  }, [
    editingApplicant,
    user,
    api,
    additionalActions,
    changePaymentStatusHandler,
    deleteApplicantsHandler,
    changePhoneNumberHandler,
    handleFailsafeImmunity,
  ]);

  const iconsRendered = useMemo(() => {
    if (!specifiedIcons) {
      return null;
    }
    return (
      <div className="application-icons-container">
        {specifiedIcons.pdfs && editingApplicant && (
          <React.Fragment>
            {renderIcons(
              "pdf",
              (value) => {
                if (!editingApplicant || !editingApplicant.filesTimestampList) {
                  return value;
                }
                const timestampFile = editingApplicant.filesTimestampList.find(
                  (file) => file.key === value,
                );
                const timestamp = timestampFile?.createdAt
                  ? getDateFormat(
                      timestampFile?.createdAt,
                      null,
                      "DD-MM-YY hh:mm:ss a",
                    )
                  : "Date not stored for this file";
                return `${timestamp} - ${value}`;
              },
              (item) => openDocument(item, "application/pdf"),
              "brown",
              "file pdf",
            )}
            {renderIcons(
              "applicationPdfKey",
              (value) => {
                if (!editingApplicant || !editingApplicant.filesTimestampList) {
                  return "Application pdf";
                }
                const timestampFile = editingApplicant.filesTimestampList.find(
                  (file) => file.key === value,
                );
                const timestamp = timestampFile?.createdAt
                  ? getDateFormat(
                      timestampFile?.createdAt,
                      null,
                      "DD-MM-YY hh:mm:ss a",
                    )
                  : "Date not stored for this file";
                return `${timestamp} - Application pdf`;
              },
              (item) => openDocument(item, "application/pdf"),
              "brown",
              "file pdf",
            )}
            {renderIcons(
              "confirmationPdfKey",
              (value) => {
                if (!editingApplicant || !editingApplicant.filesTimestampList) {
                  return "Confirmation pdf";
                }
                const timestampFile = editingApplicant.filesTimestampList.find(
                  (file) => file.key === value,
                );
                const timestamp = timestampFile?.createdAt
                  ? getDateFormat(
                      timestampFile?.createdAt,
                      null,
                      "DD-MM-YY hh:mm:ss a",
                    )
                  : "Date not stored for this file";
                return `${timestamp} - Confirmation pdf`;
              },
              (item) => openDocument(item, "application/pdf"),
              "brown",
              "file pdf",
            )}
          </React.Fragment>
        )}
        {specifiedIcons.images &&
          renderIcons(
            "imageObjects",
            (item) => {
              if (typeof item === "object") {
                return getDateFormat(
                  item.createdAt,
                  null,
                  "DD-MM-YY hh:mm:ss a",
                );
              }
              return "Timestamp is missing.";
            },
            (item) => {
              if (typeof item === "object") {
                return openDocument(item.key, "image/jpg");
              }
              return openDocument(item, "image/jpg");
            },
            "green",
            "image",
          )}
      </div>
    );
  }, [editingApplicant, specifiedIcons, renderIcons, openDocument]);

  const forceSaveModal = useMemo(
    () => (
      <Modal open={openForceSaveModal}>
        <Modal.Header>
          Validation Errors Found - Do you want to continue?
        </Modal.Header>
        {[API_NAMES.SG, API_NAMES.DS160].includes(api) && (
          <Modal.Content>{`This applicant has validation error for Arrival Date which has a value of ${moment(
            openForceSaveModal,
          ).format(
            "DD-MMM-YYYY",
          )}. Are you sure you want to save this applicant?`}</Modal.Content>
        )}
        {[API_NAMES.SCHENGEN].includes(api) && (
          <Modal.Content>{`This applicant has applied on ${moment(
            openForceSaveModal,
          ).format(
            "DD-MMM-YYYY",
          )} so it is missing some fields from the last update (8 Feb 2024) on the Schengen form. Are you sure you want to save this applicant?`}</Modal.Content>
        )}

        {[API_NAMES.DS11, API_NAMES.DS82].includes(api) && (
          <Modal.Content>
            <>
              Some of the required fields are not present in the application, do
              you still want to continue?
              <br />
              {errors && (
                <List>
                  {Object.keys(errors).map((key) => (
                    <ListItem key={key} color="red">
                      <ListIcon name="delete" />
                      <ListContent>
                        <strong>{key}: </strong>
                        {errors[key].message}
                      </ListContent>
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          </Modal.Content>
        )}
        <Modal.Actions>
          <Button negative onClick={() => setOpenForceSaveModal(false)}>
            Cancel
          </Button>
          <Button positive onClick={() => submitHandler(true)}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    ),
    [api, openForceSaveModal, submitHandler],
  );

  useImperativeHandle(
    ref,
    () => ({
      renderIcons,
      editingApplicant,
      schemaData,
      schema,
      changeState,
    }),
    [editingApplicant, renderIcons, schema, schemaData, changeState],
  );

  if (!editingApplicant || (!schemaData && api !== "inv_letter")) {
    return <Loader active />;
  }

  return (
    <Segment.Group className="applicant-view-container">
      <Sticky className="sticky">
        <Segment
          inverted
          color={editingApplicant.isFreeReapplication ? "yellow" : undefined}
          className="tableTopSegment"
        >
          <Header className="tableTitle" size="large" floated="left">
            <h3>
              {capitalizeEachWord(
                `${editingApplicant.firstName} ${editingApplicant.lastName}`,
                api === "eta" && true,
              )}
              {editingApplicant.isFreeReapplication &&
                "(THIS IS FREE REAPPLICATION)"}
            </h3>
            <h3>{getApplicantStatusString(editingApplicant.status)}</h3>
            <div>
              {api === "esta" &&
                (isManagerOrAdmin(user.roles) ||
                  user.roles.includes("L1 Agent") ||
                  user.roles.includes("L2 Agent")) &&
                editingApplicant.submittedToIvisa &&
                !["Approved", "Rejected"].includes(
                  getApplicantStatusString(editingApplicant.status),
                ) && (
                  <ConfirmPopup
                    content="Are you sure you want cancel this applicant from iVisa?"
                    callback={() => {
                      cancelFromIVisa(editingApplicant._id);
                    }}
                  >
                    <Button color="red">Cancel iVisa</Button>
                  </ConfirmPopup>
                )}
              {api === "esta" &&
                (isManagerOrAdmin(user.roles) ||
                  ivisaConfig.isManualSubmissionEnabled) &&
                getApplicantStatusString(editingApplicant.status) ===
                  "Pending submission" && (
                  <ConfirmPopup
                    content="Are you sure you want submit this applicant to iVisa?"
                    callback={() => {
                      submitToIVisa(editingApplicant._id);
                    }}
                  >
                    <Button color="linkedin">Submit to iVisa</Button>
                  </ConfirmPopup>
                )}
              <ConfirmPopup
                content={
                  <React.Fragment>
                    <Modal.Header>Logs history</Modal.Header>
                    <Modal.Content scrolling>
                      <ApplicantLogsTable applicantId={applicantId} />
                    </Modal.Content>
                  </React.Fragment>
                }
                cancelButton={null}
                callback={async () => {}}
                size="large"
              >
                <Button>Logs history</Button>
              </ConfirmPopup>
              <StatusHistory id={applicantId} api={api}></StatusHistory>
            </div>
          </Header>
          {tableHeaderCollection && api !== "inv_letter" && (
            <div className="table-header-links">
              {renderHeaderLinks()}
              <button
                className="table-link-button"
                onClick={() => {
                  setOpenAllSection(!openAllSection);
                  toastr.success(
                    `All segments are ${!openAllSection ? "opened" : "closed"}`,
                  );
                }}
              >
                {openAllSection ? "Close All" : "Open All"}
              </button>
            </div>
          )}
        </Segment>
      </Sticky>
      {forceSaveModal}
      {iconsRendered}
      <div className="edit-errors-containter">
        <ApplicantErrors id={applicantId} api={api} />
        <ApplicantEmails id={applicantId} api={api} />
      </div>

      {customHeader && <div className="custom-header">{customHeader}</div>}
      <div className="application-commons-container">
        <div style={{ display: "flex", alignItems: "center" }}>
          {editingApplicant.zendeskId && (
            <a
              href={TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(
                editingApplicant.zendeskId,
              )}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: 16 }}
            >
              {`Zendesk ID: ${editingApplicant.zendeskId}`}
            </a>
          )}
          <div>{actions}</div>
        </div>
        <ModalForm
          headerText="Change Type"
          loaderText="Preparing data..."
          onSubmit={(result) =>
            changeApplicantTypeHandler(editingApplicant.id, result)
          }
          className="change-type-modal"
          closeOnSubmit
          submitDisabled={!!editingApplicant.secondaryApplicationList.length}
          trigger={<Button>Change Type</Button>}
        >
          <ChangeTypeForm applicant={editingApplicant} />
        </ModalForm>
      </div>
      {editingApplicant.validationErrorList &&
        editingApplicant.validationErrorList.length !== 0 && (
          <div className="error-list">
            <h3>WARNING: Applicant validation on form submit failed!</h3>
            List of failed validations:
            {editingApplicant.validationErrorList.map((error) => (
              <span>{error}</span>
            ))}
          </div>
        )}
      <Table celled striped fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Property name</Table.HeaderCell>
            <Table.HeaderCell width={3}>Property value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!!groupApplicants.length && (
            <Table.Row>
              <Table.Cell>Group applicants</Table.Cell>
              <Table.Cell>
                <Table>
                  <Table.Body>
                    {groupApplicants.map((applicant) => {
                      const {
                        id,
                        applicationType,
                        firstName,
                        lastName,
                        status,
                      } = applicant;
                      return (
                        <Table.Row>
                          <Table.Cell>
                            {applicationType} - {firstName} {lastName} -{" "}
                            <a href={`/${api}/applicant/${id}`} alt="">
                              {id}
                            </a>
                            <span className="group-pack-status">
                              {" "}
                              : {status}
                            </span>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Table.Cell>
            </Table.Row>
          )}
          {generateSchema(
            schema,
            editingApplicant,
            schemaData,
            changeState,
            errors,
            openAllSection,
          )}

          <ReviewCertificationSegment
            applicant={editingApplicant}
            declarations={declarations}
            setApplicant={setEditingApplicant}
            schemaData={schemaData}
            openAllSection={openAllSection}
          />
        </Table.Body>
      </Table>
      <Sticky className="sticky-bottom">
        <Segment inverted>
          <div className="buttons-container">
            <ValidateApplication api={api} applicantId={applicantId} />
            {customSubmits}
            {(!!editingApplicant?.hasTravelPlansNotSure ||
              !!editingApplicant?.chooseLocationLater) &&
            api === API_NAMES.DS160 ? (
              <Popup
                content={
                  !!editingApplicant?.hasTravelPlansNotSure
                    ? SUBMIT_BUTTON_DISABLED_DS_TOOLTIP_1
                    : SUBMIT_BUTTON_DISABLED_DS_TOOLTIP_2
                }
                position="top right"
                trigger={
                  <span>
                    <Button positive disabled={true} onClick={() => {}}>
                      Save
                    </Button>
                  </span>
                }
              />
            ) : api === API_NAMES.GE &&
              (!!editingApplicant?.skipForNowVehicle ||
                !!editingApplicant?.skipForNowEmployments ||
                !!editingApplicant?.skipForNowDriverLicense) ? (
              <Popup
                content={SUBMIT_BUTTON_DISABLED_GE_TOOLTIP_1}
                position="top right"
                trigger={
                  <span>
                    <Button positive disabled={true} onClick={() => {}}>
                      Save
                    </Button>
                  </span>
                }
              />
            ) : (
              <Button
                positive
                onClick={async () => {
                  if (
                    await confirmPopup(
                      "Are you sure you want submit applicant changes?",
                    )
                  ) {
                    submitHandler();
                  }
                }}
              >
                Save
              </Button>
            )}
          </div>
        </Segment>
      </Sticky>
    </Segment.Group>
  );
};

export default forwardRef(ApplicantView);
